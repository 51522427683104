import React from "react";
import "./footer.css";
import webpagelogo from "../../assets/logo.svg";
const Footer = () => {
  return (
    <div className="webpage__footer section__padding ">
      <div className="webpage__footer-heading">
        <h1 className="gradient__text">
          SDo tou want to step into the future wuth others or just yourself
        </h1>
      </div>
      <div className="webpage__footer-btn">
        <p>reqesut early access</p>
      </div>
      <div className="webpage__footer-links">
        <div className="webpage__footer-links_logo">
          <img src={webpagelogo} alt="logo" />
          <p>
            this it nt a snjd jsfjsn sdhbiijs ewoihdjs osisohdois sjjnhsois
            sidubosud shbssd{" "}
          </p>
        </div>
        <div className="webpage__footer-links_div">
          <h4>links</h4>
          <p>overons</p>
          <p>socialmedia</p>
          <p>counters</p>
          <p>contact</p>
        </div>
        <div className="webpage__footer-links_div">
          <h4>links</h4>
          <p>overons</p>
          <p>socialmedia</p>
          <p>counters</p>
        </div>
        <div className="webpage__footer-links_div">
          <h4>Company</h4>
          <p>terms and conditions</p>
          <p>privacy policy</p>
          <p>contact</p>
        </div>
      </div>
      <div className="webpage__footer-copyright">
        <p>2022 WIIZB All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
