import React from "react";
import "./cta.css";

const CTA = () => {
  return <div className="webpage__cta">
    <div className="webpage__cta-content">
      <p>requesy early access to get the copy</p>
      <h3>register today amd start expllore rtheb new workmsdn isjeijdi</h3>
    </div>
    <div className="webpage__cta-btn">
      <button type="button">Get Started</button>
    </div>
  </div>;
};

export default CTA;
