import React from "react";
import { Feature } from "../../components";
import "./features.css";
const featuresData = [
   {
    title:'Improvinmg end distrusts instantly',
     text:'from thet fine john and he is gonna gimme a money and the cannot give it bakc to thew peopel'
   },
   {
    title:'The future welcomes you to the world ',
     text:'but john doesnot have the momey with him from thet fine john and he is gonna gimme a money and the cannot give it bakc to thew peopel'
   },
   {
    title:'The world is against me for a reason',
     text:'do toy really think I give a shit for these things and give them a revenge from thet fine john and he is gonna gimme a money and the cannot give it bakc to thew peopel'
   },
   {
    title:'World class Innovative informative zone',
     text:'the best firm that can teach and make others experience their future from thet fine john and he is gonna gimme a money and the cannot give it bakc to thew peopel'
   }
]
const Features = () => {
  return <div className="webpage__features section__padding">
    <div className="webpage__features-heading">
      <h1 className="gradient__text">The future is Now and You Just Need to Realize it.Step into future Today & Make it happen  </h1>
    <p>Request Early Access to Get Started</p>
    </div>
    <div className="webpage__features-container">
    {featuresData.map((item,index) => (
      <Feature title={item.title} text={item.text} key={item.title + index}/>
    ))}
    </div>
  </div>;
};

export default Features;
