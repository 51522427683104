import React from "react";
import "./brands.css";
import{google,slack,atlassian,shopify,dropbox} from './import';
const Brands = () => {
  return (
  <div className="webpage__brands section__padding">
<div>
  <img src={slack} alt="slack"/>
</div>
<div>
  <img src={atlassian} alt="atlassian"/>
</div>
<div>
  <img src={shopify} alt="shopify"/>
</div>
<div>
  <img src={dropbox} alt="dropbox"/>
</div>
<div>
  <img src={google} alt="google"/>
</div>
  </div>
)}

export default Brands;
